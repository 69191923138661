import React, { useContext, useEffect } from "react"
import { ModalContext } from "../../Context/ModalContext"
import * as imageModalStyles from "./ImageModal.module.scss"

const ImageModal = () => {
  const { isModalVisible, hideModal } = useContext(ModalContext)

  const cutoffDate = new Date("2024-12-15T04:00:00Z")

  const isBeforeCutoff = new Date() < cutoffDate

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      hideModal()
    }
  }

  if (!isModalVisible || !isBeforeCutoff) return null

  return (
    <div className={imageModalStyles.modalOverlay} onClick={handleOverlayClick}>
      <div className={imageModalStyles.modalContent}>
        <img
          src={require("../../images/index/matsuda-flyer-12-6.png").default}
          alt="Buy One Get One 50% OFF for Small Business Saturday, 11/30. Cannot be combined with insurance discounts. Some restrictions apply."
          className={imageModalStyles.modalImage}
        />
        <button
          onClick={hideModal}
          className={imageModalStyles.modalCloseButton}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default ImageModal
